import { Component } from 'react';
import { HashRouter } from 'react-router-dom';

import { Route, Switch } from 'react-router';
import Blog from "../blog";
import PayResult from "../pay-result";
import InformationProduct from "../information-product";
import StoreShop from "../store-shop";
import ViewBags from '../view-bags';

import Presentation from "../presentation";
import Claims from "../presentation/claims";
import Contact from "../presentation/contact";
import FarShipping from "../presentation/far-shipping";
import PrivacyPolicy from "../presentation/privacy-policy";
import TermsConditions from "../presentation/terms-conditions";

class ArtesaniasGb extends Component {
    render() {
        return (
            <HashRouter>
                <Switch>
                    <Route path={"/"} exact component={ViewBags}/>
                    <Route path={"/productos"} exact component={ViewBags}/>
                    <Route path={"/producto/:producto"} exact component={this.renderByProduct}/>
                    <Route path={"/blog"} exact component={Blog}/>
                    <Route path={"/pay/success"} exact component={PayResult}/>
                    <Route path={"/pay/failure"} exact component={PayResult}/>
                    <Route path={"/pay/pending"} exact component={PayResult}/>
                    <Route path={"/blog/publicaciones/:publicación"} exact component={this.renderEntries}/>
                    <Route path={"/tienda"} exact component={StoreShop}/>
                    <Route path={"/presentation"} exact component={Presentation}/>
                    <Route path={"/contacto"} exact component={Contact}/>
                    <Route path={"/envio/lejano"} exact component={FarShipping}/>
                    <Route path={"/terminos/condiciones"} exact component={TermsConditions}/>
                    <Route path={"/politica/privacidad"} exact component={PrivacyPolicy}/>
                    <Route path={"/reclamos"} exact component={Claims}/>
                    <Route path={"/detalles"} exact component={InformationProduct}/>
                    <Route path={"/filtrar/productos/:type/:ocassion/"} render={this.renderByOcation}/>
                    <Route path={"/productos/:modalitie"} render={this.renderModalities}/>
                </Switch>
            </HashRouter>
        );

    }


    renderModalities = (rendProps) => {
        const {match} = rendProps;
        const {params} = match;
        const {modalitie} = params;
        if(modalitie !== undefined) {
            return <ViewBags key={'sel-view-bags'} modalitie={modalitie}/>
        } else {
            return <ViewBags/>
        }
    }

    renderByOcation = (rendProps) => {
        const {match} = rendProps;
        const {params} = match;
        const {type,  ocassion} = params;
        if(type !== undefined && ocassion !== undefined ) {
            return <ViewBags key={'sel-view-bags'} type={type} ocassion={ocassion}/>
        } else {
            // return <ViewBags/>
        }
    }

    renderEntries = (rendProps) => {
        const {match} = rendProps;
        const {params} = match;
        const {publicacion} = params;
        if(publicacion !== undefined ) {
            return <Blog entrySel={publicacion}/>
        } else {
            return <Blog/>
        }
    }

    renderByProduct = (rendProps) => {
        const {match, location} = rendProps;
        const {params} = match;
        const {producto} = params;
        const urlParams = new URLSearchParams(location.search);
        const title = urlParams.get('title');
        if(producto !== undefined ) {
            return <InformationProduct product={producto} title={title}/>
        } else {
            return <span>Loading</span>
        }
    }

}

export default ArtesaniasGb
