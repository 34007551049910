import React, {Component} from "react";

class MenuPresentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [
        {"title": "Informacion General", "path":"#/presentation", "icon" : "circle"},
        {"title": "Reclamos", "path":"#/reclamos", "icon" : "square"},
        {"title": "Envio Lejano", "path":"#/envio/lejano", "icon" : "disc"},
        {"title": "Contacto", "path":"#/contacto", "icon" : "circle"},
        {"title": "Blog", "path":"#/blog", "icon" : "square"},
        {"title": "Tienda", "path":"tienda.html", "icon" : "disc"},
        {"title": "Productos", "path":"index.html", "icon" : "circle"},
        {"title": "Politica de Privacidad", "path":"#/politica/privacidad", "icon" : "square"},
        {"title": "Terminos y Condiciones", "path":"#/terminos/condiciones", "icon" : "disc"}
      ]
    };
  }

  render() {
    const {state} = this;

    let content = state.pages.map((pag,i) => {return <li className={i===this.props.active ? "active" : ""} type="square"><a href={pag["path"]}>{pag["title"]}</a></li>})

    return (
      <ol>
        {content}
      </ol>
    );
  }
}

export default MenuPresentation;