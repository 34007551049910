import React, { Component } from 'react';
import './slide-menu.scss';
import { Link } from "react-router-dom";

class SlideMenu extends Component {
  state = {
    menuActive: false,
    selectedModality: null,
  };

  // Maneja el cambio de modalidad
  handleModalityChange = (modality) => {
    this.setState({ selectedModality: modality }, () => {
      this.props.changeView(modality.title);
      this.state.menuActive && this.toggleMenu();
    });
  };

  // Abre o cierra el menú
  toggleMenu = () => {
    this.props.showContent();
    this.setState((prevState) => ({ menuActive: !prevState.menuActive }));
  };

  render() {
    const { modalities: allModalities, modalitie, colorTitle } = this.props;
    const { menuActive } = this.state;
    // Filtra las modalidades basadas en el estado del menú
    let filteredModalities = [];
    if(allModalities.length > 0) {
   
      filteredModalities = (() => {
        const selected = allModalities.filter(moda => moda.title.toLowerCase() === modalitie.toLowerCase());
        const nonSelected = allModalities.filter(moda => moda.title.toLowerCase() !== modalitie.toLowerCase());
  
        if (menuActive) {
          return [...selected, ...nonSelected];
        }
  
        const clientWidth = document.documentElement.clientWidth;
        const additionalModalities = clientWidth < 900
          ? nonSelected.slice(0, 1)
          : nonSelected.slice(0, clientWidth/250);
  
        return [...selected, ...additionalModalities];
      })();
  
    }
   
    // Crea el contenido del menú
    // if(!menuActive) {
    //   filteredModalities = allModalities.slice(0,3);
    // } else {
    //   filteredModalities = allModalities;
    // }
    const contentMod = filteredModalities.map(moda => (
      <Link
        key={`${moda.title}-item`}
        onClick={() => this.handleModalityChange(moda)}
        to={`/productos/${moda.title}`}
      >
        <li>
          <div className="ic-services-ic"></div>
          <h3 className={`${moda.title.toLowerCase() === modalitie.toLowerCase() ? "is-active" : ""} m-01`}>
            <abbr title={`Filtrar: productos ${moda.title}`}>{moda.title}</abbr>
          </h3>
        </li>
      </Link>
    ));

    return (
      <div className={`content-slide-menu ${menuActive ? "cnt-serv-act" : ""}`}>
        <div>
          {/* {menuActive && (
            <a href={'/'}>
              <div className="img-comp">
                <h2 className="title-artesaniasgb">{Consts.txTitle}</h2>
              </div>
            </a>
          )} */}
          <h1  onClick={this.toggleMenu} className={`${colorTitle} ${menuActive && 'c-w'}`}>
            <abbr title={"Que te trae por aquí hoy?"}>Modalidades</abbr>
          </h1>
        </div>
        <ul className={`content-modalities d-f ov-au ${menuActive ? "menu-ls-active" : ""}`}>
          {contentMod}

          <div>
          <div
            onClick={this.toggleMenu}
            className={`icon-menu ${menuActive ? "itm-menu-active icon-menu-white" : ""} cur-p`}
          ></div>
        </div>
        </ul>
       
      </div>
    );
  }
}

export default SlideMenu;
