import React, {Component} from 'react';
import './alert-dialog.scss';
import * as Consts from '../../pages/common/constants/const';

class AlertDialog extends Component {

    render() {

        return (
            <div className={`content-info-alert`}>
                <div className="content-alert">
                    <span onClick={() => this.props.onCancel()} className={"btn-close"}>X</span>
                   <div className='d-f fd-c'>
                   <span className="title-alert">{this.props.title}</span>
                    <small className="title-description">{this.props.description}</small>
                   </div>
                    {this.props.content}
                    <div className="actions-alert">
                        <div className={"btn-confirm"} onClick={ () => this.props.onConfirm()}><span>{Consts.txConf}</span></div>
                        <div className={"btn-cancel"} onClick={() => this.props.onCancel()}><span>{Consts.txCanc}</span></div>

                    </div>
                </div>
            </div>
        );
    }
}

export default AlertDialog;