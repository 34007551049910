import axios, {AxiosError, AxiosResponse} from "axios";
import {BigNumber} from "bignumber.js"
import Session from "../../sandbox/session";

interface ResultData {
    result: string
    to: string
    from: string
    updated: string
}

interface RequestData {
    amount: string
    to: string
    from: string
}
export let setGlobalCurrencies: Array<string> = ['COP'];

const converter = {
    async convert(data: RequestData): Promise<ResultData> {
        // https://api.coinbase.com/v2/prices/COP/spot
        // https://cdn.jsdelivr.net/gh/prebid/currency-file@1/latest.json
        let axiosData: undefined | any = Session.instance.dataCurrencies;
        if(axiosData === undefined) {
            await axios.get("https://api.coinbase.com/v2/prices/COP/spot")
                .then((res: AxiosResponse) => {
                    if (res.data) {
                        let currency = {"conversions": {  "COP": {} }};
                        let currencies: any = currency['conversions']['COP'];
                        currencies['COP']  = 1;

                        res.data.data.forEach((curr: any) => {
                            if(setGlobalCurrencies.length !== res.data.data.length+1)
                                setGlobalCurrencies.push(curr.base);
                            currencies[curr.base]  = parseFloat(curr.amount);
                            currency['conversions']['COP'] = currencies;
                        });
                        axiosData = currency;
                        Session.instance.dataCurrencies  = currency;
                    } else {
                        throw new Error("response data is null.")
                    }
                }).catch((err: AxiosError) => {
                    throw err
                });
        }

        if (!axiosData.conversions || !axiosData.conversions.COP) {
            throw new Error("response format error")
        }
        if (!axiosData.conversions.COP[data.from]) {
            throw new Error("unsupported currency: " + data.from)
        }
        if (!axiosData.conversions.COP[data.to]) {
            throw new Error("unsupported currency: " + data.to)
        }
        return {
            to: data.to,
            from: data.from,
            updated: axiosData.dataAsOf,
            result:  new BigNumber(parseFloat(data.amount) / parseFloat(axiosData.conversions.COP[data.to])).toFixed(6).toString()
        }
    }
}


const utils = {
    async getAllSupportCurrency(): Promise<Array<string>> {
        let axiosData: undefined | any = undefined
        await axios.get("https://cdn.jsdelivr.net/gh/prebid/currency-file@1/latest.json")
            .then((res: AxiosResponse) => {
                if (res.data) {
                    axiosData = res.data
                } else {
                    throw new Error("response data is null.")
                }
            }).catch((err: AxiosError) => {
                throw err
            })
        if (!axiosData.conversions || !axiosData.conversions.USD) {
            throw new Error("response format error")
        }
        const currencyList: Array<string> = []
        for (let val in axiosData.conversions.USD) {
            currencyList.push(val)
        }
        return currencyList
    }
}

export {
    converter,
    utils
}
