import React, {Component} from 'react';
import './claims.scss';

import Header from '../../common/header';
import 'sweetalert2/src/sweetalert2.scss'
import "react-datepicker/dist/react-datepicker.css";
import MenuPresentation from '../menu-presentation';
import Session from "../../../sandbox/session";

class Claims extends  Component {

  constructor(props) {
    super(props);

    this.state = {
      step:1
    };
  }

  render() {

    return <body  className="body-content-view">

    <Header header={<h2 className={"title-page"}>ArtesaniasGb</h2>} store={false} />
    <div className="content">
      <div className={"content-men"}>
        <MenuPresentation active={1} />

      </div>
      <div className={"content-body"}>
        <h1>Reclamos</h1>
        <article>
          <p>
            {Session.instance.country["claims"]}
          </p>
        </article>
      </div>
    </div>
    </body>
  }


}


export default Claims;
