import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './reducers/store';
import { Provider } from 'react-redux';
import ArtesaniasGb from './pages/common/ArtesaniasGb';


ReactDOM.render(
    <Provider store={store}>
        <ArtesaniasGb page={"productos"}/>
    </Provider>,
  document.getElementById('content-artesaniasgb-main')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
