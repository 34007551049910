
import React, {Component} from 'react';
import './alert-image.scss';

class AlertImage extends Component {

    render() {

        return (
            <div onClick={() => this.props.onCancel()} className={`content-info-alert`}>
                <div className="info-image" style={{backgroundImage:`url(${this.props.images})`}}>
                </div>
            </div>
        );
    }
}

export default AlertImage;