import React, {Component} from 'react';
import './blog.scss';
import Header from "../common/header";
import ListEntries from "./list-entries";
import {connect} from "react-redux";
import {GetEntries, GetEntry} from "../../reducers";
import  * as Consts from "../common/constants/const";


class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      entry:"blog"

    };
  }

  UNSAFE_componentWillMount() {
    this.props.GetEntries(Consts.slugCountry);
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {


    if(nextProps.entrySel !== undefined && nextProps.entrySel !== this.state.entry) {
      let item = nextProps.entries.filter((ent) => ent["title"] === nextProps.entrySel)[0];
      if(item !== undefined) {
        this.setState({
          entry: item["title"]
        }, ()=>{
          this.props.GetEntry(Consts.slugCountry, this.state.entry);
        });
      }
    }

  }

  render() {
    let item = this.props.entry[0] || [];

    return (
      <body  className="body-content-view">

        <Header header={<h2 className={"title-page"}>Blog</h2>} store={false} />
        <div className="content">
          <div className={"content-men"}>
            <ListEntries active={this.state.entry} entries={this.props.entries}/>
          </div>
          <div className={"content-body"}>
            <h1>{this.state.entry}</h1>
            <img alt={item["drive_key_principal"]} className={"image-publication"} src={item["drive_key_principal"]}></img>
            <p disabled readOnly value={item["body"]} className={"info-publication"} rows="4" cols="50">
              {item["body"]}
            </p>
          </div>
        </div>
      </body>
    )
  }
}


export default connect(
  (state) => ({entries: state.setInfoData.entries, entry: state.setInfoData.entry}),
  {GetEntries, GetEntry})
(Blog);
