import React, {Component } from 'react';
import './filter-by-products.scss';

import * as Consts from "../../pages/common/constants/const";
import ItemsCarousel from "react-items-carousel";
import {groupByNumber} from "../../rsc/utils";

class FilterByProducts extends Component {
  constructor(props){
    super(props);

    this.state = {
      activeItemIndex : 0,
      childrenn: []
    }
  }

  UNSAFE_componentWillMount () {
    if(this.props.categories) {

      this.setState({
        childrenn:  this.createChildren2(this.props.categories)
      })
    }


  }



  createChildren2 = (categories) => {
    let catGroup = groupByNumber(5, categories);

    return catGroup.map(i => {
      let content = []
      i.forEach((item)=>{content.push(<div className={"content-info-filter cur-p"} onClick={() => {this.props.onFilter(item);}}>{item["title"]}</div>)})
      return (
        <div className={"content-items-filter"}>
          {content}
        </div>
      )
    });
  }



  componentDidMount() {

  }


  changeActiveItem = (activeItemIndex) => {
    this.setState({ activeItemIndex :  activeItemIndex});

  };

  render() {
    return(
          <div>
            <ItemsCarousel
              // Placeholder configurations
              enablePlaceholder
              numberOfPlaceholderItems={1}
              minimumPlaceholderTime={500}
              placeholderItem={<div className="content-place-caro">{Consts.txSugg}</div>}

              // Carousel configurations
              numberOfCards={window.innerWidth > 1400 ? 3 : window.innerWidth > 800 ? 2 : 1}
              gutter={12}
              showSlither={true}
              firstAndLastGutter={true}
              freeScrolling={true}

              // Active item configurations
              requestToChangeActive={this.changeActiveItem}
              activeItemIndex={this.state.activeItemIndex}
              activePosition={'center'}

              chevronWidth={14}
              rightChevron={'>'}
              leftChevron={'<'}
              outsideChevron={true}
              slidesToScroll={1}
            >
              {this.state.childrenn}
            </ItemsCarousel>
          </div>
    )
  }
}


export default FilterByProducts
