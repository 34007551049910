import React, {Component} from 'react';
import './type-services-content.scss';
import BusquedaComponent from '../busqueda-component';


class TypeServicesContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modV : 0
    };
  }

  changeMod = (v) => {
    this.setState({
      modV :v.id
    }, () => {
      this.props.changeView(v.title);
      return;
    });
  };

  render() {

    return(
      <ul className={"content-sub-header"}>
        <BusquedaComponent onSearch={(textToSearch)=>{this.props.onSearch(textToSearch);}} />
      </ul>
    );
  }
}

export default TypeServicesContent;
