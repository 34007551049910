// BusquedaComponent.js

import React, { Component } from 'react';
import './busqueda-component.scss';

class BusquedaComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      query: '', // Estado para almacenar la consulta de búsqueda
      results: [], // Estado para almacenar los resultados
    };
  }

  handleInputChange = (event) => {
    const query = event.target.value;
    this.setState({ query }, () => {
      // Realizar la búsqueda cuando se actualice el estado de la consulta
      this.search();
    });
  };

  search = () => {

    // Aquí debes realizar la petición AJAX usando fetch o axios
    // por ejemplo, llamando a tu API REST con la consulta de búsqueda
    // y actualizando el estado 'results' con los datos obtenidos.
    // Luego, puedes renderizar los resultados en tu componente.
    const { query } = this.state;
    this.props.onSearch(query);

  // fetch(`https://api.example.com/search?query=${query}`)
  //   .then((response) => response.json())
  //   .then((data) => {
  //     this.setState({ results: data.results });
  //   })
  //   .catch((error) => {
  //     console.error('Error al buscar:', error);
  //   });
  };

  render() {
    const { query, results } = this.state;

    return (
      <div className="content-bar-search">
        <div className="search-wrapper">
          <span className="search-icon">🔍</span> {/* Aquí puedes usar un ícono o una imagen */}
          <input
            type="text"
            placeholder="Buscar por producto, marca, característica..."
            value={query}
            onChange={this.handleInputChange}
          />
        </div>
        <ul>
          {results.map((result) => (
            <li key={result.id}>{result.name}</li>
          ))}
        </ul>
      </div>
    );
  }
}

export default BusquedaComponent;
