import React, {Component} from 'react';
import './presentation.scss';

import Header from '../common/header';
import 'sweetalert2/src/sweetalert2.scss'
import "react-datepicker/dist/react-datepicker.css";
import MenuPresentation from './menu-presentation';
import Session from '../../sandbox/session';
import {Helmet} from "react-helmet";

class Presentation extends  Component {

  constructor(props) {
    super(props);

    this.state = {
      step:1
    };
  }

  render() {

    return <body  className="body-content-view">
    <Helmet>
      <meta charSet="utf-8" />
      <title>Domicilios gratis en bogota | ArtesaniasGb</title>
    </Helmet>
    <Header header={<h2 className={"title-page"}>ArtesaniasGb</h2>} store={false} />
    <section className="content">
      <section className={"content-men"}>
        <MenuPresentation active={0} />
      </section>
      <section className={"content-body"}>
        <h1>Informacion General</h1>
        <article>
          <p>
            {Session.instance.country["general_information"]}
          </p>
        </article>
      </section>
    </section>
    </body>
  }


}


export default Presentation;
