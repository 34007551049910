import React, { Component } from "react";
import "./pay-result.scss";
import { connect } from "react-redux";
import logo from '../../rsc/assets/logoArtesanias1.svg';
import {VerifyTransactionStatus} from '../../reducers';
class PayResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  componentDidMount(){

    const queryParams = window.location.hash.split("?")[1];
    this.props.VerifyTransactionStatus(queryParams);
  }

  render() {
    let titleResponse = ""
    let color = ""
    let background_color = ""
    if(window.location.hash.indexOf("pay/success") !== -1) {
      titleResponse = "exitoso";
      color = "green";
      localStorage.removeItem('itemsshop');
    }
    if(window.location.hash.indexOf("pay/failure") !== -1) {
      titleResponse = "fallido";
      color = "red";
      background_color = "b-red";
    }
    if(window.location.hash.indexOf("pay/pending") !== -1) {
      titleResponse = "pendiente";
      color = "yellow";
      background_color = "b-yellow";
      localStorage.removeItem('itemsshop');

    }
    const urlParams = new URLSearchParams(window.location.hash);
    return (
      <div className="body-content-view">
       
        <div className="content">
          <div className="container">
            <img src={logo} alt="Logo" className="logo" />
            <h1 className={'title '+ color}>Pago {titleResponse}</h1>
            <div className="details">
              {/* <div>
                <p className="label">Monto pagado</p>
                <p className="value">$100.000</p>
              </div> */}
              <div>
                <p className="label">Estado de pago</p>
                <p className="value">{urlParams.get('collection_status')}</p>
              </div>
            </div>
            <div>
            <div>
                <p className="label">Referencia de pago</p>
                <p className="value">{urlParams.get('external_reference')}</p>
              </div>
              <div>
                <p className="label">Método de pago</p>
                <p className="value">{urlParams.get('payment_type')}</p>
              </div>
              {/* <div>
                <p className="label">Fecha de pago</p>
                <p className="value">27/11/2023</p>
              </div> */}
            </div>
            
            <button onClick={()=>{
              window.location.hash = "/"; 
            }} className={"button " + background_color}>Volver al inicio</button>
          </div>
        </div>
      </div>
    );
  }
}

export default connect(
  (state) => ({
    transaction_status: state.setInfoData.transaction_status,

  }),
  { VerifyTransactionStatus }
)(PayResult);
