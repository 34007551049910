import React, {Component} from 'react';
import './menu-create-bag.scss';

class MenuCreate extends  Component {
  constructor(props) {
    super(props);
    this.state = {
      steps: [{"name": "Paso 1"}, {"name": "Paso 2"}, {"name": "Paso 3"}, {"name": "Paso 4"}, {"name": "Paso 5"},],
      step: 0,
    }
  }

  UNSAFE_componentWillMount() {
    if(this.props.steps !== undefined){
      this.setState({
        steps : this.props.steps,
      });
    }
  }


  render() {
    var steps2 = [];
    this.state.steps.forEach((step, i) => {
        steps2.push(
          <div id={"step"+step["name"]} ref={(cont)=>{ this.i = cont;}} className="content-step" onClick={i > this.state.step +1 ? false : () => {this.changeStep(i+1);}}>
            {
              this.icon(i+1 === this.state.step ? "✓" : i+1, i > this.state.step +2 ? "inactive" : i === this.state.step ? "progress" : "")
            }
            <span
                  className={'text-step ' + (i+1 === this.props.step.toString() ? "o-1" : "")+` ${this.state.step+1 > i ? "o-1" : "o-03"}`}>{step["name"]}</span>
            {
              this.divider(this.state.step > i ? "o-1" : "o-02")
            }
          </div>
          );


     });

    return <div className="content-menu">
      {
        steps2
      }


    </div>

  }

  changeStep(step) {
    this.setState({
      step: step-1
    });
    this.props.changeStep(step);
  }

  divider(active) {
    var clientWidth = document.documentElement.clientWidth;

    var classWidth = "w-11";

    if(clientWidth < 700) {

      classWidth = "w-0"

    }
    return <div className={`${classWidth} divider-step ${active} `}>

    </div>
  }
  icon(step, clas) {
    return <div className={`icon-step ${clas}`}>
      <span>
        {step}
      </span>

    </div>
  }


}


export default MenuCreate;