import Emmiter from './emitter';
const singleton = Symbol();
const singletonEnforcer = Symbol();

class Session extends Emmiter {
  constructor(enforcer) {
    super();
    if (enforcer !== singletonEnforcer) {
      throw new Error('Cannot construct singleton');
    }
    this._user = JSON.parse(localStorage.getItem('user'));
    this._total = 0;
    this._products = [];
    this._dataCurrencies = undefined;
    this._currencyUser = JSON.parse(localStorage.getItem('currencyUser')) || 'COP';
    this._viewProduct = {};
    this._questions = {};
    this._country = JSON.parse(localStorage.getItem('country'));
    this._countries = JSON.parse(localStorage.getItem('countries'));
    this._countrySelect = JSON.parse(localStorage.getItem('countrySelect'));
    this._city = JSON.parse(localStorage.getItem('city'));
    this._citySelect = JSON.parse(localStorage.getItem('citySelect'));
    this._days_delivery = JSON.parse(localStorage.getItem('days_delivery'));
    this._citySelected = JSON.parse(localStorage.getItem('citySelected'));
    this._infoDestinatary = JSON.parse(localStorage.getItem('infoDestinatary'));



    if (!this._country) {
      localStorage.setItem('country', JSON.stringify({}));
    }
    if (!this._countries) {
      localStorage.setItem('countries', JSON.stringify({}));
    }
    if (!this._days_delivery) {
      localStorage.setItem('days_delivery', JSON.stringify(""));
    }

    if (!this._infoDestinatary) {
      localStorage.setItem('infoDestinatary', JSON.stringify({}));
    }
    if (!this._user || (!this._user.isAuthenticated)) {
      this._user = {
        'isAuthenticated': false,
        'token': null,
        'info': null,
      };
      localStorage.setItem('user', JSON.stringify(this._user));
    } else {
      //this.login;
    }


  }


  static get instance() {
    if (!this[singleton]) {
      this[singleton] = new Session(singletonEnforcer);
    }
    return this[singleton];
  }

  get currencyUser() {
    return this._currencyUser;
  }

  set currencyUser(value) {
    localStorage.setItem('currencyUser', JSON.stringify(value));
    this._currencyUser = value;
  }

  get dataCurrencies() {
    return this._dataCurrencies;
  }

  set dataCurrencies(value) {
    this._dataCurrencies = value;
  }

  get user() {
    return this._user;
  }

  set user(value) {
    this._user = value;
  }
  get viewProduct() {
    return this._viewProduct;
  }

  set viewProduct(value) {
    this._viewProduct = value;
  }
  get questions() {
    return this._questions;
  }

  set questions(value) {
    this._questions = value;
  }
  get country() {
    return this._country;
  }

  set country(value) {
    localStorage.setItem('country', JSON.stringify(value));

    this._country = value;
  }

  get total() {
    return this._total;
  }

  set total(value) {
    this._total = value;
  }

  get products() {
    return this._products;
  }

  set products(value) {
    localStorage.setItem('products', JSON.stringify(value));
    this._products = value;
  }

  get isAuthenticated() {
    return this._isAuthenticated;
  }

  set isAuthenticated(value) {
    this._isAuthenticated = value;
  }

  get countries() {
    return JSON.parse(localStorage.getItem('countries'));
  }

  set countries(value) {
    localStorage.setItem('countries', JSON.stringify(value));
    this._countries = value;
  }

  get countrySelect() {
    return JSON.parse(localStorage.getItem('countrySelect'));
  }

  set countrySelect(value) {
    localStorage.setItem('countrySelect', JSON.stringify(value));
    this._countrySelect = value;
  }
  get cities() {
    return JSON.parse(localStorage.getItem('cities'));
  }

  set cities(value) {
    localStorage.setItem('cities', JSON.stringify(value));
    this._cities = value;
  }

  get citySelect() {
    return JSON.parse(localStorage.getItem('citySelect'));
  }

  set citySelect(value) {
    localStorage.setItem('citySelect', JSON.stringify(value));
    this._citySelect = value;
  }
  get days_delivery() {
    return JSON.parse(localStorage.getItem('days_delivery'));
  }

  set days_delivery(value) {
    localStorage.setItem('days_delivery', JSON.stringify(value));
    this._days_delivery = value;
  }

  set citySelected(value) {
    localStorage.setItem('citySelected', JSON.stringify(value));
    this._citySelected = value;
  }
  get citySelected() {
    return JSON.parse(localStorage.getItem('citySelected'));
  }
  set infoDestinatary(value) {
    localStorage.setItem('infoDestinatary', JSON.stringify(value));
    this._infoDestinatary = value;
  }
  get infoDestinatary() {
    return JSON.parse(localStorage.getItem('infoDestinatary'));
  }
  
}

export default Session;
