import React, {Component} from 'react';
import './currency-section.scss';
import InputAutocomplete from "../input-autocomplete";
import {setGlobalCurrencies} from "../../lib/currency-converter";
import Session from "../../sandbox/session";


class CurrencySection extends Component{


  constructor(props, context) {
    super(props, context);
    this.state = {
      mode: 0, // 0 == view -  1 == edit
      value: Session.instance.currencyUser  , // 0 == view -  1 == edit
    }
  }

  componentDidMount() {
    if(Session.instance.currencyUser === "")
      Session.instance.currencyUser = "COP";
  }

  render() {
    const {state} = this;
    let componentReturn = [];

    if (state.mode === 0) {
      componentReturn = <span className=''
      // onClick={this.onChangeMode.bind(this)}
      >{state.value}</span>;
    } else {
      componentReturn =  <InputAutocomplete autoComplete={'off'} onEditCurrency={this.onEditCurrency.bind(this)} onCancel={this.onChangeMode} id={"inputCurrencyId"} value={state.value} name={"inputCurrency"} title={""} placeholder={"Currency"} required={true} listAutocomplete={setGlobalCurrencies}/>
    }
    return <div className='content-currency'>
      {componentReturn}
    </div>
  }

  onEditCurrency(e) {
    const {id} = e.target;
    if(id !== this.state.value && id !== "") {
      this.setState({
        value: id
      });
      Session.instance.currencyUser = id;
    }
    this.props.updatePrices();

   this.onChangeMode();
  }
  onChangeMode = () => {
   this.setState({mode: this.state.mode === 0 ? 1 : 0});
  }
}


export default CurrencySection;
