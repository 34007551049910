import {
  apiGetBreakfasts,
  apiGetProductsSuggestions,
  apiGetProductSuggestions,
  apiGetBankList,
  apiGetTypes,
  apiGetOcassions,
  apiGetModalities,
  apiOnGeneratePay,
  apiGetQuestions,
  apiGetCategories,
  apiGetProductsBySubCategory,
  apiOnGetEntries,
  apiOnGetEntry,
  apiGetCountry,
  apiGetBag,
  apiGetCountries,
  apiGetCities,
  apiOnGetPromotions,
  apiOnGetLastSales,
  apiOnSearchProducts,
  apiOnGetTimeDelivery,
  apiOnVerifyStatus
} from '../lib/api';
import Session from '../sandbox/session';
import * as Consts from '../pages/common/constants/const';
const initialState = {
  setInfoData: [],
  bags: [],
  users: [],
  roles: [{ "name": "Implementation Agent", "value": "implementation_agent", "state": false }, { "name": "Support Agent", "value": "support_agent", "state": false }, { "name": "Manager", "value": "manager", "state": false }, { "name": "Admin", "value": "admin", "state": false }],
  alert: { "state": false, "message": "" },
  products: [],
  productsFilter: [],
  ocassions: [],
  modalities: [],
  entries: [],
  entry: [],
  bag: [],
  countries: [],
  cities: [],
  lastsales: [],
  promotions: [],
  productForgotten: {},
  setdata_search: [],
  time_delivery: [],
  transaction_status: [],
  city: [],
  infoDestinatary: Session.instance.infoDestinatary
};

const GET_BREAKFASTS = 'GET_BREAKFASTS';
const GET_COUNTRIES = 'GET_COUNTRIES';
const GET_CITIES = 'GET_CITIES';
const GET_TYPES = 'GET_TYPES';
const GET_BREAKFASTS1 = 'GET_BREAKFASTS1';
const GET_BAG = 'GET_BAG';
const GET_BREAKFASTS2 = 'GET_BREAKFASTS2';
const GET_PRODUCTS = 'GET_PRODUCTS';
const ADD_PRODUCT = 'ADD_PRODUCT';
const GET_OCASSIONS = 'GET_OCASSIONS';
const GET_PRODUCTS_SUGGESTIONS = 'GET_PRODUCTS_SUGGESTIONS';
const GET_BANK_LIST = 'GET_BANK_LIST';
const GET_PRODUCT_SUGGESTIONS = 'GET_PRODUCT_SUGGESTIONS';
const GET_MODALITIES = 'GET_MODALITIES';
const GET_QUESTIONS = 'GET_QUESTIONS';
const ON_PAY = 'ON_PAY';
const GET_CATEGORIES = 'GET_CATEGORIES';
const GET_PRODS_BY_CATEGORY = 'GET_PRODS_BY_CATEGORY';
const GET_ENTRIES = 'GET_ENTRIES';
const GET_ENTRY = 'GET_ENTRY';
const GET_COUNTRY = 'GET_COUNTRY';
const GET_PROMOTIONS = 'GET_PROMOTIONS';
const GET_LAST_SALES = 'GET_LAST_SALES';
const ON_SEARCH_PRODUCTS = 'ON_SEARCH_PRODUCTS';
const GET_TIME_DELIVERY = 'GET_TIME_DELIVERY';
const VERIFY_TRANSACTION_STATUS = 'VERIFY_TRANSACTION_STATUS';
const SET_CITY = 'SET_CITY';
const SET_INFO_DESTINATARY= 'SET_INFO_DESTINATARY=';



const getTypes = (types) => ({ type: GET_TYPES, payload: types });
const getOcassions = (ocassions) => ({ type: GET_OCASSIONS, payload: ocassions });
const getBreakfast = (bags) => ({ type: GET_BREAKFASTS, payload: [...bags] });
const getBag = (bag) => ({ type: GET_BAG, payload: bag });
const getProductsSuggestions = (products) => ({ type: GET_PRODUCTS_SUGGESTIONS, payload: [...products] });
const getProductSuggestions = (productForgotten) => ({ type: GET_PRODUCT_SUGGESTIONS, payload: productForgotten });
const getBankList = (setBanks) => ({ type: GET_BANK_LIST, payload: [...setBanks] });
const getProducts = (products) => ({ type: GET_PRODUCTS, payload: products });
const addProduct = (products) => ({ type: ADD_PRODUCT, payload: products });
const getModalities = (modalities) => ({ type: GET_MODALITIES, payload: modalities });
const onPay = (infoPay) => ({ type: ON_PAY, payload: infoPay });
const getQuestions = (questions) => ({ type: GET_QUESTIONS, payload: questions });
const getCategories = (categories) => ({ type: GET_CATEGORIES, payload: categories });
const getProductsByCategory = (productsFilter) => ({ type: GET_PRODS_BY_CATEGORY, payload: productsFilter });
const onGetEntries = (entries) => ({ type: GET_ENTRIES, payload: entries });
const onGetEntry = (entry) => ({ type: GET_ENTRY, payload: entry });
const onGetCountry = (country) => ({ type: GET_COUNTRY, payload: country });
const onGetCountries = (countries) => ({ type: GET_COUNTRIES, payload: countries });
const onGetCities = (cities) => ({ type: GET_CITIES, payload: cities });
const onGetLastSales = (lastsales) => ({ type: GET_LAST_SALES, payload: lastsales });
const onGetPromotions = (promotions) => ({ type: GET_PROMOTIONS, payload: promotions });
const onSearchProducts = (setdata_search) => ({ type: ON_SEARCH_PRODUCTS, payload: setdata_search });
const getTimeDelivery = (time_delivery) => ({ type: GET_TIME_DELIVERY, payload: time_delivery });
const verifyTransactionStatus = (transaction_status) => ({ type: VERIFY_TRANSACTION_STATUS, payload: transaction_status });
const setCity = (city) => ({ type: SET_CITY, payload: city });
const setInfoDestinatary = (infoDestinatary) => ({ type: SET_INFO_DESTINATARY, payload: infoDestinatary });


export const GetProducts = () => {

  return (dispatch) => {
    apiGetTypes()
      .then(res => {
        dispatch(getProducts(initialState.products));
      })
      .catch(res => {
      })
  }
};
export const GetModalities = () => {

  return (dispatch) => {
    apiGetModalities()
      .then(res => {
        dispatch(getModalities(res));
      })
      .catch(res => {
      })
  }
};

export const AddProduct = (product) => {
  return (dispatch) => {
    dispatch(addProduct(product));
  }
};

export const GetTypes = (modalitie) => {
  return (dispatch) => {
    apiGetTypes(modalitie)
      .then(res => {
        dispatch(getTypes(res));
      })
      .catch(res => {
      })
  }
};

export const GetOcassions = (ocassion) => {
  return (dispatch) => {
    apiGetOcassions(ocassion)
      .then(res => {
        dispatch(getOcassions(res));
      })
      .catch(res => {
      })
  }
};

export const GetBag = (filter) => {

  return (dispatch) => {
    apiGetBag(filter)
      .then(res => {
        dispatch(getBag(res));

      })
      .catch(res => {
      })
  }
};

export const GetProductsSuggestions = (filter) => {

  return (dispatch) => {
    apiGetProductsSuggestions(filter)
      .then(res => {
        dispatch(getProductsSuggestions(res));

      })
      .catch(res => {
      })
  }
};
export const GetProductForgotten = (api) => {

  return (dispatch) => {
    apiGetProductSuggestions()
      .then(res => {
        dispatch(getProductSuggestions(res));
      })
      .catch(res => {
      })
  }
};
export const GetQuestions = (api) => {

  return (dispatch) => {
    apiGetQuestions()
      .then(res => {
        dispatch(getQuestions(res));
      })
      .catch(res => {
      })
  }
};
export const GetCountry = () => {

  return (dispatch) => {
    apiGetCountry()
      .then(res => {
        dispatch(onGetCountry(res));
      })
      .catch(res => {
      })
  }
};
export const GetCountries = () => {

  return (dispatch) => {
    apiGetCountries()
      .then(res => {
        dispatch(onGetCountries(res));
      })
      .catch(res => {
      })
  }
};
export const GetCities = (country) => {

  return (dispatch) => {
    apiGetCities(country)
      .then(res => {
        dispatch(onGetCities(res));
      })
      .catch(res => {
      })
  }
};
export const GetBankList = (body) => {

  return (dispatch) => {
    apiGetBankList(body)
      .then(res => {
        dispatch(getBankList(res));
      })
      .catch(res => {
      })
  }
};
export const GetCategories = () => {

  return (dispatch) => {
    apiGetCategories()
      .then(res => {
        dispatch(getCategories(res));
      })
      .catch(res => {
      })
  }
};
export const GetProductsByCategory = (category) => {

  return (dispatch) => {
    apiGetProductsBySubCategory(category)
      .then(res => {
        dispatch(getProductsByCategory(res));
      })
      .catch(res => {
      })
  }
};


export const GetBreakfasts = (filter, clean) => {

  return (dispatch) => {
    apiGetBreakfasts(filter)
      .then(res => {
        Session.instance.nextpage = res['next'];
        if(clean) {
          initialState.bags = res['results']
        } else {
          initialState.bags = [...initialState.bags, ...res['results']]
        }
        dispatch(getBreakfast(initialState.bags));
      })
      .catch(res => {
      })
  }
}

export const GetBreakfasts1 = (filter) => {

  return (dispatch) => {
    apiGetBreakfasts(filter)
      .then(res => {
        dispatch(getBreakfast(res));
      })
      .catch(res => {
      })

  }
};

export const GetBreakfasts2 = (filter) => {

  return (dispatch) => {
    apiGetBreakfasts(filter)
      .then(res => {
        dispatch(getBreakfast(res));
      })
      .catch(res => {
      })
  }
};

export const OnPay = (body) => {

  return (dispatch) => {
    apiOnGeneratePay(body)
      .then(res => {
        dispatch(onPay(res));
      })
      .catch(res => {
      })
  }
};
export const GetEntries = (country) => {

  return (dispatch) => {
    apiOnGetEntries(country)
      .then(res => {
        dispatch(onGetEntries(res));
      })
      .catch(res => {
      })
  }
};
export const GetEntry = (country, title) => {

  return (dispatch) => {
    apiOnGetEntry(country, title)
      .then(res => {
        dispatch(onGetEntry(res));
      })
      .catch(res => {
      })
  }
};
export const GetLastSales = (country, title) => {

  return (dispatch) => {
    apiOnGetLastSales(country, title)
      .then(res => {
        dispatch(onGetLastSales(res));
      })
      .catch(res => {
      })
  }
};
export const GetPromotions = (country, slugFactory) => {

  return (dispatch) => {
    apiOnGetPromotions(country, slugFactory)
      .then(res => {
        dispatch(onGetPromotions(res));
      })
      .catch(res => {
      })
  }
};

export const SearchProducts = (country, textToSearch) => {

  return (dispatch) => {
    apiOnSearchProducts(country, textToSearch)
      .then(res => {
        dispatch(onSearchProducts(res));
      })
      .catch(res => {
      })
  }
};


export const GetTimeDelivery = (slug_factory, city_destiny) => {

  return (dispatch) => {
    apiOnGetTimeDelivery(slug_factory, city_destiny)
      .then(res => {
        dispatch(getTimeDelivery(res));
      })
      .catch(res => {
      })
  }
};


export const VerifyTransactionStatus = (queryParams) => {

  return (dispatch) => {
    apiOnVerifyStatus(queryParams)
      .then(res => {
        dispatch(verifyTransactionStatus(res));
      })
      .catch(res => {
      })
  }
};


export const SetCity = (city) => {
  return (dispatch) => {
    GetTimeDelivery(Consts.slugFactory, city);
    dispatch(setCity(city));
  }
};

export const SetInfoDestinatary = (infoDestinatary) => {
  return (dispatch) => {
    Session.instance.infoDestinatary = infoDestinatary;
    dispatch(setInfoDestinatary(infoDestinatary));
  }
};


const principal = (state = initialState, action) => {
  switch (action.type) {
    case GET_BAG:
      return { ...state, bag: action.payload };
    case GET_BREAKFASTS:
      return { ...state, bags: action.payload };
    case GET_PRODUCTS_SUGGESTIONS:
      return { ...state, products: action.payload };
    case GET_PRODUCT_SUGGESTIONS:
      return { ...state, productForgotten: action.payload };
    case GET_BREAKFASTS1:
      return { ...state, bags1: action.payload };
    case GET_BREAKFASTS2:
      return { ...state, bags2: action.payload };
    case GET_TYPES:
      return { ...state, types: action.payload };
    case GET_OCASSIONS:
      return { ...state, ocassions: action.payload };
    case GET_MODALITIES:
      return { ...state, modalities: action.payload };
    case GET_PRODUCTS:
      return { ...state, products: action.payload };
    case GET_BANK_LIST:
      return { ...state, setBanks: action.payload };
    case ON_PAY:
      return { ...state, infoPay: action.payload };
    case GET_QUESTIONS:
      return { ...state, questions: action.payload };
    case GET_CATEGORIES:
      return { ...state, categories: action.payload };
    case GET_PRODS_BY_CATEGORY:
      return { ...state, productsFilter: action.payload };
    case GET_ENTRIES:
      return { ...state, entries: action.payload };
    case GET_ENTRY:
      return { ...state, entry: action.payload };
    case GET_COUNTRY:
      return { ...state, country: action.payload };
    case GET_COUNTRIES:
      return { ...state, countries: action.payload };
    case GET_CITIES:
      return { ...state, cities: action.payload };
    case GET_LAST_SALES:
      return { ...state, lastsales: action.payload };
    case GET_PROMOTIONS:
      return { ...state, promotions: action.payload };
    case ADD_PRODUCT:
      return { ...state, products: state.products.filter(u => u.title !== action.payload.title).concat(action.payload) };
    case ON_SEARCH_PRODUCTS:
      return { ...state, setdata_search: action.payload};
    case GET_TIME_DELIVERY:
      return { ...state, time_delivery: action.payload};
    case VERIFY_TRANSACTION_STATUS:
      return { ...state, transaction_status: action.payload};
    case SET_CITY:
      return {
        ...state,
        city: action.payload,
      };
    case SET_INFO_DESTINATARY:
      return {
        ...state,
        infoDestinatary: action.payload,
      }
    default:
      return { ...state };
  }
}
export default principal;
