import React, {Component} from "react";

class ListEntries extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: [
        /*
        {"title": "Bag Nutritivo", "path":"#/presentation", "icon" : "circle"},
        {"title": "Bag Energetico", "path":"#/reclamos", "icon" : "square"},
        {"title": "Desayunar Saludable", "path":"#/envio/lejano", "icon" : "disc"},
        {"title": "Tipos de Bag", "path":"#/contacto", "icon" : "circle"},
        {"title": "ArtesaniasGb", "path":"#/blog", "icon" : "square"},
        {"title": "Bags Comunes", "path":"tienda.html", "icon" : "disc"},
        {"title": "Desayunar / No desayunar?", "path":"index.html", "icon" : "circle"},
        {"title": "Bag", "path":"#/politica/privacidad", "icon" : "square"},
        {"title": "Comida Importante", "path":"#/terminos/condiciones", "icon" : "disc"}*/
      ]
    };
  }


  render() {
    const {props} = this;


    let content = props.entries.map((pag,i) => {
      let clsActive = `${pag["title"].toLowerCase() === this.props.active.toLowerCase() ? "active" : ""}`;
      return <li className={clsActive} type="square"><a className={clsActive} href={'#/blog/publicaciones/'+pag["title"]+"/"}>{pag["title"]}</a></li>
    });

    return (
      <ol>
        {content}
      </ol>
    );
  }
}

export default ListEntries;