import React, { Component } from "react";
import { connect } from "react-redux";
import "./header.scss";
import Session from "../../../sandbox/session";
import * as Consts from "../../common/constants/const";
import LocationDelivery from "../../../components/location-delivery";
import CurrencySection from "../../../components/currency-section";
import AlertDialog from "../../../components/alert-dialog";
import ItemInformation from "../../../components/item-information";
import { GetCities, GetCountries, SetCity, SetInfoDestinatary} from "../../../reducers";

class Header extends Component {
  state = {
    Nombre: this.props.infoDestinatary.Nombre || "",
    Telefono: this.props.infoDestinatary.Telefono || "",
    EmailAddress: this.props.infoDestinatary.EmailAddress || "",
    Country: this.props.infoDestinatary.Country || "",
    City: this.props.infoDestinatary.City || "",
    Direccion: this.props.infoDestinatary.Direccion || "",
    days_delivery: Session.instance.days_delivery || false,
    selectingLocation: false,
    displayBarInformation: true,
  };

  componentDidMount() {
    const { infoDestinatary } = this.props;
    if ("Country" in infoDestinatary) {
      this.props.GetCities(infoDestinatary.Country.toLowerCase());
    }
  }

  onLogout = () => {
    Session.instance.logout();
  };

  selectCountry = async (key, value) => {
    const dataChange = this.props.infoDestinatary;
    dataChange[key] = value;

    this.props.SetInfoDestinatary(dataChange);

    this.setState({ [key]: value });
    Session.instance.countrySelect = value;
    await this.props.GetCities(value.toLowerCase());
  };

  handleChangeData = (key, value) => {
    const dataChange = this.props.infoDestinatary;
    dataChange[key] = value;
    this.setState({ [key]: value });
    this.props.SetInfoDestinatary(dataChange);
    
  };

  selectCity = (key, value, pattern, id) => {
    this.setState({ [key]: value, id });
    const citySelected = this.props.cities.find((city) => city.id === parseInt(id, 10));

    const dataChange = this.props.infoDestinatary;
    dataChange[key] = value;
    this.props.SetInfoDestinatary(dataChange);

    Session.instance.citySelect = value;
    Session.instance.days_delivery = citySelected.days_delivery;
    Session.instance.citySelected = citySelected;

    console.log(value, citySelected)
    this.props.SetCity(citySelected.id);

    this.setState({
      days_delivery: citySelected.days_delivery
    })
  };

  toggleLocationSelection = () => {
    this.setState((prevState) => ({ selectingLocation: !prevState.selectingLocation }), () =>{
      if (this.state.selectingLocation) {
        document.body.style.overflow = 'hidden'; // Previene el scroll
      } else {
        document.body.style.overflow = 'unset';

      }
    });
   
  };

  informationNeedToBuy = () => (
    <div className="content-select_country">
      {this.selectLocationDelivery()}
      {this.basicInformationClient()}
    </div>
  );

  selectLocationDelivery = () => (
    <div className="content-select_country">
      <ItemInformation
        state="Country"
        pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
        title="País"
        data={Session.instance.countries}
        value={this.state.Country}
        typeInput="select"
        valid={true}
        changeValue={this.selectCountry}
      />
      <ItemInformation
        state="City"
        pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
        title="Ciudad"
        data={this.props.cities}
        value={this.state.City}
        typeInput="select"
        valid={true}
        changeValue={this.selectCity}
      />
      <ItemInformation
        state="Direccion"
        pattern={/^[a-zA-Z][a-zA-Z ]{1,50}$/}
        title="Dirección"
        lengthMax={50}
        value={this.state.Direccion}
        valid={true}
        changeValue={this.handleChangeData}
      />
    </div>
  );

  basicInformationClient = () => (
    <div>
      <div className="d-f fd-c py-2">
        <span className="title-auxiliary">Información de compra</span>
        <small className="title-description">La información es requerida para realizar compras</small>
      </div>
      <ItemInformation
        state="Nombre"
        pattern={/^[a-zA-Zá-ú][a-zA-Zá-ú./`’ ]{1,50}$/}
        title="Nombre"
        value={this.state.Nombre}
        valid={true}
        changeValue={this.handleChangeData}
      />
      <ItemInformation
        state="Telefono"
        pattern={/^[0-9]{10}$/}
        title="Teléfono"
        value={this.state.Telefono}
        valid={true}
        changeValue={this.handleChangeData}
      />
      <ItemInformation
        state="EmailAddress"
        pattern={/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/}
        title="Correo Electrónico"
        lengthMax={50}
        value={this.state.EmailAddress}
        valid={true}
        changeValue={this.handleChangeData}
      />
    </div>
  );

  render() {
    const { days_delivery, selectingLocation, displayBarInformation } = this.state;

    return (
      <header className="body-header">
        {selectingLocation && (
          <AlertDialog
            title={Consts.txSelCoun}
            description={Consts.txDescLocation}
            content={this.informationNeedToBuy()}
            onCancel={this.toggleLocationSelection}
            onConfirm={this.toggleLocationSelection}
          />
        )}

        {displayBarInformation && (
          <div className="bar-information">
            <span className="tx-bar ta-c">
              <div className="ic ic-dinero" aria-hidden="true"></div>
              {Consts.txBar1}
            </span>
            <CurrencySection updatePrices={this.props.updatePrices} />
            <LocationDelivery
              Country={this.state.Country}
              City={this.state.City}
              onSelectLocation={this.toggleLocationSelection}
            />
            {days_delivery && (
              <abbr title={`Llega en ${days_delivery} días hábiles`}>
                <div className="d-f jc-c ai-c">
                  Llega {days_delivery === '1' ? 'Hoy' : `en ${days_delivery} día${days_delivery > 1 ? 's' : ''}`}
                </div>
              </abbr>
            )}
            <span className="tx-bar ta-c tt-i">
              <div className="ic ic-cambio" aria-hidden="true"></div>
              {Consts.txBar2}
            </span>
            <a className="tx-presentation d-f" href="#/presentation">
              <h1 className="tx-bar ta-c m-n">${Consts.txTitle}</h1>
            </a>
            <div className="ct-info-sec">
              <span className="tt-lc ta-c mr-4">
                <a className="col-th" href={`mailto:${Consts.emailContact}`} aria-label="Contact email">
                  {Consts.emailContact}
                </a>
              </span>
              {!this.props.store && (
                <div className="icons-rs">
                  <a href="/#/tienda" id="shopp" className="icons-rs shop" aria-label="Shop">
                    <span className="d-n">Tienda</span>
                  </a>
                  {this.props.storeItems?.length > 0 && (
                    <div className="counter-shop">
                      {this.props.storeItems.length}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}

        <header id="application-header" className="header">
          <div className={`head-view ${this.props.store ? "fd-r hv-store" : ""}`}>
            <a href="/">
              <div className="content-title">
                <h2 className="title-artesaniasgb">{Consts.txTitle}</h2>
              </div>
            </a>
            {this.props.header ? this.props.header : <h1 className="subtitle">{Consts.txMsgGen}</h1>}
            {window.location.hash.indexOf("tienda") === -1 && (
              <div className="content-icons">
                <div className="icons-rs">
                  <div onClick={() => window.open(Consts.txInstagram)} className="icon ins" />
                  <div onClick={() => window.open(Consts.txWhatsapp)} className="icon wapp" />
                  <div onClick={() => window.open(Consts.txFacebook)} className="icon fbook" />
                  <div className="icon space" />
                </div>
              </div>
            )}
          </div>
        </header>
      </header>
    );
  }
}

const mapStateToProps = (state) => ({
  cities: state.setInfoData.cities,
  countries: state.setInfoData.countries,
  infoDestinatary: state.setInfoData.infoDestinatary

});

export default connect(mapStateToProps, { GetCountries, GetCities, SetCity, SetInfoDestinatary })(Header);
