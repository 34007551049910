import React, { Component } from "react";

import Header from "../common/header";
import MenuCreate from "../../components/menu-create-bag";
import ItemStore from "../../components/item-store";
import "./store-shop.scss";
import Session from "../../sandbox/session";
import { connect } from "react-redux";
import {
  GetProducts,
  GetProductsSuggestions,
  GetProductForgotten,
  GetBankList,
  GetCategories,
  GetProductsByCategory,
  GetTimeDelivery
} from "../../reducers";
import { formatCop, getClientIp, getJSessionId } from "../../rsc/utils";
import ItemsCarousel from "react-items-carousel";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import DatePicker from "react-datepicker/dist/es";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import * as Consts from "../common/constants/const";
import FilterByProducts from "../../components/filter-by-products";
import { apiOnGenerateCCPay } from "../../lib/api";
import { stringify, parse } from "flatted";
import CryptoJs from "crypto-js";
// import { Helmet } from "react-helmet";
import image_mp from '../../rsc/assets/mercado_pago.png';

const toastMixin = Swal.mixin({
  toast: true,
  icon: "success",
  title: "General Title",
  animation: false,
  position: "top-right",
  showConfirmButton: false,
  timer: 3000,
  timerProgressBar: true,
  didOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});
class StoreShop extends Component {
  constructor(props) {
    super(props);
    var today = new Date();

    this.state = {
      step: 1,
      total: 0,
      envio: 0,
      contentBreakfasts: [],
      listTotal: [],
      bags: [],
      NameTitular: "",
      NameTitularValid: false,
      Card: "",
      CardValid: false,
      Expiration: "",
      ExpirationValid: false,
      CvvValid: false,
      CVV: "",
      lengthCvv: 3,
      activeItemIndex: 0,
      childrenn: [],
      storeItems: [],
      messageDelivery: "",
      detailsDelivery: "",
      remNameDelivery: "",
      remEmailDelivery: "",
      remPhoneDelivery: "",
      destNameDelivery: "",
      destPhoneDelivery: "",
      destDirectionlDelivery: "",
      destCityDelivery: "",
      NumberDocument: "",
      remDedDelivery: true,
      destStateDelivery: "",
      messageDeliveryValid: false,
      detailsDeliveryValid: false,
      remNameDeliveryValid: false,
      remEmailDeliveryValid: false,
      remPhoneDeliveryValid: false,
      remDedDeliveryValid: false,
      destNameDeliveryValid: false,
      destPhoneDeliveryValid: false,
      destDirectionlDeliveryValid: false,
      // destCodePostalDeliveryValid: false,
      destCityDeliveryValid: false,
      saveDataInformation: false,
      methodsPay: [
        {
          id: 0,
          title: "Efectivo",
          image:
            "",
        },
        {
          id: 1,
          title: "En Línea -  Mercadopago",
          image:
            "",
        },
      ],
      methodPay: 1,
      NumberDocumentValid: false,
      DocumentIdentificationValid: false,
      TypeClientValid: false,
      BankAccountValid: false,
      dateDelivery: new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        12,
        0,
        0
      ).setDate(new Date().getDate() + 10),
      validateForm: false,
      TypeClient: "CC",
      DocumentIdentification: "Documento Identificación",
      BankAccount: "Entidad Bancaria",
      txtBtonSuggestion: Consts.txAdd,
      ResponseUrl: "http://localhost:8000/api/transactionpse/validate/",
      CodeBank: "",
      deviceSessionId: "",
      ipAddress: "",
      onFilterProducts: false,
      timeEstimatedBigger: 2,
      hourMin: 7,
    };
  }

  changeStep = (step) => {
    this.setState({ step: parseInt(step) });
  };

  componentDidUpdate(prevProps) {
    const { city, infoDestinatary, time_delivery } = this.props;

    if (city !== prevProps.city) {
      this.fetchStoreData(city);
    }
    if (infoDestinatary !== prevProps.infoDestinatary) {
      this.setInfoDestinatary(infoDestinatary);
    }

    if (time_delivery !== prevProps.time_delivery) {
      this.updateTotal(this.state.listTotal)
    }
    
  }

  componentDidMount() {
    const { city, infoDestinatary  } = this.props;
    if (city) {
      this.fetchStoreData(city);
    }
    if (infoDestinatary) {
      this.setInfoDestinatary(infoDestinatary);
    }
  }

  setInfoDestinatary = async (infoDestinatary) => {
    this.setState({
      remNameDelivery: infoDestinatary.Nombre,
      remEmailDelivery: infoDestinatary.EmailAddress,
      remPhoneDelivery: infoDestinatary.Telefono,
      destNameDelivery: infoDestinatary.Nombre,
      destPhoneDelivery: infoDestinatary.Telefono,
      destDirectionlDelivery: infoDestinatary.Direccion,
      destCityDelivery: infoDestinatary.City,
    });

  }

  fetchStoreData = async (city) => {
    this.setState({ loading: true, error: null }); // Iniciar carga y resetear el error
    await this.props.GetTimeDelivery(Consts.slugFactory, this.props.city);
    await this.updateTotal(this.state.listTotal);

  };
 

  calculateDeliverySpend = async() => {

    var accum = 0;
    this.state.storeItems.map((lst) => {
      let vol_weight = ((lst['altura'] * lst['longitud'] * lst['anchura'])/3000)*lst['quantity'];
      accum = vol_weight+accum;
      return vol_weight;
    });

    let total_flete = this.props.time_delivery.price_kg;
    if(accum > 1) {
      total_flete += (accum-1)*this.props.time_delivery.price_kg_additional;
    }

    await this.setState({
      envio: parseInt(total_flete, 10),
      vol_weight: accum.toFixed(2),
    });
  }


  changeProduct = async (bag, inc) => {
    console.warn("changeProduct", inc);
    this.addProductStore(bag, inc);
  };

  async UNSAFE_componentWillMount() {
    await this.props.GetCategories();
    await this.props.GetProductsSuggestions();
    await this.props.GetProductForgotten();
    if(Session.instance.citySelected) {
      await this.props.GetTimeDelivery(Consts.slugFactory, Session.instance.citySelected.id);
    }

    await this.setSessionId();
    await this.setIpAddress();
    let storeItems = localStorage.getItem("itemsshop");
    if (storeItems) storeItems = parse(storeItems);
    await this.mountBreakfasts(storeItems);


    this.setState({
      childrenn: this.createChildren(this.props.productsSuggestions),
    });
  }

  setSessionId = async () => {
    let sessionId = await getJSessionId();
    sessionId = sessionId + moment().unix();

    this.setState({
      deviceSessionId: CryptoJs.MD5(sessionId).toString(),
    });
  };
  setIpAddress = async () => {
    let ipAddress = await getClientIp();
    this.setState({
      ipAddress: ipAddress,
    });
  };

  mountBreakfasts = async (storeItems) => {
    if (storeItems) {
      var bagss = [];
      var listTotal = [];
      var listUpdate = [];
      let timeEstimatedBigger = this.state.timeEstimatedBigger;
      await storeItems.map((breaks) => {
        breaks["quantity"] =
          breaks["quantity"] !== undefined ? breaks["quantity"] : 1;
        var tit = breaks["price"]
          ? (breaks["price"] * breaks["quantity"]).toString()
          : "0";
        listTotal.push(tit);
        bagss.push(
          <ItemStore
            deleteProduct={this.deleteProductStore.bind(this)}
            changeProduct={this.changeProduct.bind(this)}
            bag={breaks}
          />
        );
        listUpdate.push(breaks);
        if (breaks["time_estimated"] > timeEstimatedBigger) {
          timeEstimatedBigger = breaks["time_estimated"];
          this.setState({
            timeEstimatedBigger,
          });
        }
        return breaks;
      });
      this.setState({
        storeItems: listUpdate,
        contentBreakfasts: bagss,
        listTotal: listTotal,
      });
      localStorage.setItem("itemsshop", stringify(listUpdate));
      await this.updateTotal(listTotal);

    }
  };

  updateTotal = async (list) => {
    const total = list.reduce((acc, value) => acc + parseInt(value), 0);
  
    await this.setState({ total });
  
    await this.calculateDeliverySpend();
  };
  

  render() {
    var content;
    switch (this.state.step) {
      case 1:
        content = this.productos();
        break;
      case 2:
        content = this.stepFive();
        break;
      default:
        break;
    }

    return (
      <div className="body-content-view">
        {/* <Helmet>
          <meta charSet="utf-8" />
          <title>Tienda a domicilo | Artesanias Gb | Hyper Super</title>
        </Helmet> */}
        <Header
          header={
            <MenuCreate
              steps={[
                { name: Consts.txProds },
                { name: Consts.txPayment },
              ]}
              step={this.state.step}
              changeStep={this.changeStep}
            />
          }
          store={true}
        />
        <div className=" content-view cv2">
          {content}
          <span className={"butt-ind-right"}>☞</span>
        </div>
        {/* <script type="text/javascript" src={'https://maf.pagosonline.net/ws/fp/tags.js?id='+this.state.deviceSessionId+'80200'}></script>
    <noscript>
      <iframe title="pagosoline" src={"https://maf.pagosonline.net/ws/fp/tags.js?id="+this.state.deviceSessionId+"80200"}></iframe>
    </noscript> */}
      </div>
    );
  }

  productos() {
    return (
      <div
        className={`content-store-shop ${
          window.innerWidth < 700 ? "style-pzed" : ""
        }`}
      >
        <div className="col1-store-s">
          {this.headerStep(
            Consts.txProdSugg,
            `${this.state.contentBreakfasts.length} ${
              this.state.contentBreakfasts.length === 1
                ? Consts.txProd
                : Consts.txProds
            } por comprar`,
            false,
            <ItemsCarousel
              // Placeholder configurations
              enablePlaceholder
              numberOfPlaceholderItems={1}
              minimumPlaceholderTime={500}
              placeholderItem={
                <div className="content-place-caro">{Consts.txSugg}</div>
              }
              // Carousel configurations
              numberOfCards={
                window.innerWidth > 1400 ? 3 : window.innerWidth > 800 ? 2 : 1
              }
              gutter={12}
              showSlither={true}
              firstAndLastGutter={true}
              freeScrolling={true}
              // Active item configurations
              requestToChangeActive={this.changeActiveItem}
              activeItemIndex={this.state.activeItemIndex}
              activePosition={"center"}
              chevronWidth={14}
              rightChevron={">"}
              leftChevron={"<"}
              outsideChevron={true}
              slidesToScroll={1}
            >
              {this.createChildren(
                this.props.productsFilter.length === 0
                  ? this.props.productsSuggestions
                  : this.props.productsFilter
              )}
            </ItemsCarousel>,
            this.state.onFilterProducts ? (
              <FilterByProducts
                onFilter={this.filterBarSuggestion}
                categories={this.props.categories}
              ></FilterByProducts>
            ) : (
              false
            )
          )}
          <div className="content-store">{this.state.contentBreakfasts}</div>
        </div>
        <div className="col2-store-s">
          <span className="notify-note">{Consts.txMesStore}</span>
          <span>
            {Consts.txSubTot}:{" "}
            <span className="text-price">
              <br />
              {formatCop(this.state.total)}
            </span>
          </span>
          <span>
            {Consts.txEnvio}:{" "}
            <span className="text-price">
              <br />
              {Consts.txEnvio} {formatCop(this.state.envio)} ~ {this.state.vol_weight} kg
            </span>
          </span>
          <h3 className="tt-up">
          {Consts.txTot}
            <h3 className="text-price ta-c">
              <br />
            {" "}{Consts.txCurrency} {formatCop(this.state.total+this.state.envio)}
            </h3>
          </h3>
          {
            <span
              onClick={() => {
                this.changeStep(2);
              
                this.changeStep(2);
              }}
              className="button-continue"
            >
              {Consts.txPay}
            </span>
            
          }
          {
             <span
             className="ta-c cur-p"
             onClick={() => {
               document.getElementById("stepPago").click();
             }}
           >
             {Consts.txCont}
           </span>
          }
        </div>
      </div>
    );
  }

  filterBarSuggestion = async (item) => {
    await this.props.GetProductsByCategory(item["title"]);
    this.onFilterProducts();
  };

  handleChangeDate = (date) => {
    this.setState({
      dateDelivery: date,
    });
  };

  changeTextBtnSugg = () => {
    if (this.state.txtBtonSuggestion === Consts.txAdd) {
      this.setState({ txtBtonSuggestion: "Cambiar" });
    }
  };

  offChangeTextBtnSugg = () => {
    if (this.state.txtBtonSuggestion === "Cambiar") {
      this.setState({ txtBtonSuggestion: Consts.txAdd });
    }
  };

  changeMethodPay = (method) => {
    this.setState({
      methodPay: method,
    });
  };

  stepFive() {
    let currentComponent = this;
    let contentProductsDelivery = [];
    if (this.state.storeItems)
      this.state.storeItems.map((itms) => {
        let imageItem = itms["images"][0]
          ? itms["images"][0]["drive_key"]
          : "images/im-n-disp.png";
        return contentProductsDelivery.push(
          <div className={"cnt-item-res"}>
            {itms["images"] &&
            itms["images"].length > 0 &&
            itms["images"][0]["drive_key"] &&
            itms["images"][0]["drive_key"].split("mp4").length > 1 ? (
              <video
                className={`cnt-image-res`}
                controls
                src={`${Consts.urlStatic}${imageItem}`}
              ></video>
            ) : (
              <div
                className={"cnt-image-res"}
                style={{
                  backgroundImage: `url(${Consts.urlStatic}${imageItem})`,
                }}
              ></div>
            )}
            <span className={"title-min-targ"}>{itms.title}</span>
          </div>
        );
      });

    let productForgotten = { title: "No products available", price: "0" };

    if (this.props.productForgotten) {
      productForgotten = this.props.productForgotten;
    }
    let daysMore = 1 ;
    let date = new Date();
    let newHourMin = 7;

    newHourMin = 7 + parseInt(this.state.timeEstimatedBigger);
    if (newHourMin > 22) {
      //"The time more 1 day", Math.round(newHourMin /21),newHourMin /21,newHourMin
      daysMore = Math.round(newHourMin / 21);
      newHourMin = parseInt(this.state.timeEstimatedBigger) - daysMore * 13;
    }

    if (typeof this.state.dateDelivery !== "number") {
      if (this.state.dateDelivery.getDate() > date.getDate() + daysMore) {
        newHourMin = 7;
      }
    }
    if (this.state.hourMin !== newHourMin) {
      this.setState({
        hourMin: newHourMin,
      });
    }

    let dateDelivery = date.setDate(date.getDate() + parseInt(Session.instance.days_delivery));

    var imageItemForgotten = "";
    if (productForgotten["images"] && productForgotten["images"].length > 0) {
      imageItemForgotten = productForgotten["images"][0]["drive_key"];
    }
    /*
    if(date.getDate() >   typeof this.state.dateDelivery === "number" ? new Date(Math.round(this.state.dateDelivery/1000)).getDate() : this.state.dateDelivery.getDate()){
      newHourMin = 7;
    } else {
      newHourMin = this.state.hourMin;

    }
*/

    /*
    if(hoursNow > 15) {
      daysMore = 1;
      let timeLeft = 24-hoursNow +7;
      if(this.state.timeEstimatedBigger  > timeLeft) {
        let newHourMin = 7 + this.state.timeEstimatedBigger - timeLeft;

        if(newHourMin > 22) {
          daysMore =   Math.round(newHourMin /24);
          newHourMin = 7;

        }
        if(this.state.hourMin !== newHourMin) {
          this.setState({
            hourMin: newHourMin
          });
        }

      }
    }*/
    return (
      <div
        className={`content-pay ${window.innerWidth < 700 ? "style-pzed" : ""}`}
      >
        <div className={"content-header-pay3"}>
          <div
            className={`body-p-suggestion ${
              window.innerWidth < 700 ? "style-pzed" : ""
            }`}
          >
            <span className={"title-sugg"}>{Consts.txAdd} esto!</span>

            <div className={"content-item-forg"}>
              <div className={"ct-txt-forg"}>
                <span className={"txt-item-forg"}>
                  {productForgotten["title"]}
                </span>
                <span className={"txt-item-forg"}>
                  {formatCop(productForgotten["price"]?.toString())}
                </span>
              </div>
              <div
                onMouseOut={this.offChangeTextBtnSugg}
                onMouseOver={this.changeTextBtnSugg}
                id={"iconChangeSugg"}
                ref={(ref) => {
                  this.iconChangeSugg = ref;
                }}
                onClick={() => {
                  currentComponent.props.GetProductsSuggestions();
                  currentComponent.props.GetProductForgotten();
                }}
                className={"icon-change ic-c-forg"}
              >
                <span>Cambiar</span>
              </div>
              <div className={"ct-image-forgotten"}>
                <div
                  className={"cnt-image-res"}
                  style={{
                    width: "100%",
                    height: "8em",
                    backgroundImage: `url(${Consts.urlStatic}${imageItemForgotten})`,
                  }}
                ></div>
              </div>

              <div className={"btn-add-forgotten"}>
                <span
                  onClick={() => {
                    currentComponent.addProductStore(
                      productForgotten,
                      productForgotten.quantity + 1,
                      0
                    );
                  }}
                >
                  {this.state.txtBtonSuggestion}
                </span>
              </div>
            </div>
          </div>
          {this.headerStep(
            "Metodo de pago",
            "Seleccione un metodo de pago",
            [],
            <div>
              <div>
                <h4 className={"txt-message-header"}>
                  {Consts.txThbuy} {this.state.remNameDelivery}.{" "}
                  {Consts.txMsgBuy}
                </h4>
                <p className={"txt-mail-header"}>
                  {this.state.remEmailDelivery} es el mail de notificacion.
                </p>
              </div>
            </div>,
            null
          )}
          <span className={"title-total-3"}>
            {Consts.txTot} {formatCop(this.state.total+this.state.envio)} <small>{Consts.txCurrency}</small>
          </span>
        </div>
        <p className={"txt-terms-header"}>
          <input
            checked={this.state.termsAccepted}
            value={this.state.termsAccepted}
            onClick={() => {
              this.setState({
                termsAccepted: !this.state.termsAccepted,
              });
            }}
            type="checkbox"
            name="user"
            id="fuser"
          />
          <label htmlFor="fuser">
            <span>
              He leído y acepto los{" "}
              <span
                onClick={() => {
                  Swal.fire({
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                    title: Consts.terms,
                    html:
                      "<h2 style='text-align: center;'><strong> " +
                      Consts.terms +
                      "</strong></h2><p>&nbsp;</p>" +
                      Session.instance.country["terms_conditions"],
                  });
                }}
              >
                términos y condiciones
              </span>
              y la
              <span
                onClick={() => {
                  Swal.fire({
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    allowOutsideClick: false,
                    title: "Política de privacidad",
                    html:
                      "<h1>Privacy Policy for " +
                      Consts.txTitle +
                      "</h1>" +
                      Session.instance.country["privacy_policy"],
                  });
                }}
              >
                política de privacidad.
              </span>
            </span>
          </label>
        </p>
        <div className={"section-middle-pay"}>
          <div className={"content-data-pay"}>
            <div
              className={"content-title-mp"}
              onClick={() =>
                this.changeMethodPay(
                  this.state.methodPay === this.state.methodsPay.length - 1
                    ? 0
                    : this.state.methodPay + 1
                )
              }
            >
              <span className={"fs-1m title-methodpay"}>
                {(this.state.methodsPay[this.state.methodPay] &&
                  this.state.methodsPay[this.state.methodPay].title) ||
                  ""}
              </span>
              <div className={"icon-change"}></div>
            </div>

            {this.state.methodPay === 0 ? (<div></div>) : ([])}
            {this.state.methodPay === 1 ? (<img alt="mercadopago-logo" src={image_mp}/>) : ([])}

            <span onClick={this.onGeneratePay} className="w-98 button-continue">
              {Consts.txPay}
            </span>
          </div>
          <div
            className={`${
              window.innerWidth < 700 ? "style-pzed" : ""
            } cnt-colm2-delivery`}
          >
            <span className={"title-methodpay"}>{Consts.txDateDel}</span>

            <DatePicker
              selected={this.state.dateDelivery}
              onChange={this.handleChangeDate}
              showTimeSelect
              minDate={dateDelivery}
              minTime={date.setHours(this.state.hourMin, 1)}
              maxTime={date.setHours(22, 30)}
              dateFormat="MMMM d, yyyy h:mm aa"
              timeSelected={date.setHours(7, 1)}
            />
            <span className={"c-w tit-carr title-methodpay"}>Carrito</span>
            <div className={"content-icon-truck"}>
              <div className={"content-items-truck"}>
                {contentProductsDelivery}
              </div>
            </div>
          </div>
        </div>
        <div className="content-info-pay"></div>
      </div>
    );
  }

  deleteProductStore = async (product) => {
    this.setState({
      storeItems: [],
      contentBreakfasts: [],
      listTotal: 0,
    });
    var products = [...this.state.storeItems];
    var repProd = await products.filter((p) => {
      return p["identifier"] !== product["identifier"];
    });
    const newP = repProd;

    await this.mountBreakfasts(newP);
    //this.changeStep(2);
  };

  addProductStore = (product, inc, count) => {
    var products = [...this.state.storeItems];
    var indexToEdit = null;
    // eslint-disable-next-line
    var productRepeated = products.filter((p, index) => {
      if (p["identifier"] === product["identifier"]) {
        indexToEdit = index;
        return p;
      }
    });
    if (productRepeated.length > 0) {
      if (
        productRepeated[0]["quantity"] < productRepeated[0]["quantity_store"]
      ) {
        // var prodWithoutRepeat = products.filter((p) => {return p["identifier"] !== product["identifier"]});
        productRepeated[0]["quantity"] =
          inc === false ? count : productRepeated[0]["quantity"] + inc;
        let resultado = products;
        resultado[indexToEdit] = productRepeated[0];
        products = resultado;
        toastMixin.fire({
          position: "top-end",
          icon: "success",
          title: Consts.txProdAddUpd,
          showConfirmButton: false,
          timer: 750,
        });
      } else {
        toastMixin.fire({
          position: "top-end",
          icon: "error",
          title: Consts.txProdSold,
          showConfirmButton: true,
          timer: 2000,
        });
      }
    } else {
      toastMixin.fire({
        position: "top-end",
        icon: "success",
        title: Consts.txProdAdd,
        showConfirmButton: false,
        timer: 750,
      });
      products.push(product);
    }
    this.mountBreakfasts(products);
  };

  createChildren = (products) =>
    products.map((i) => {
      return (
        <div
          onClick={() => {
            this.addProductStore(i, 1, 0);
          }}
          className={"item-car-sugg"}
        >
          <span className={"tit-acc"}>{Consts.txAdd}?</span>
          <div className={"content-item-suggestions"}>
            <div className={"content-info-suggestion"}>
              <h5>* {i.category}</h5>
              <h4>{i.title}</h4>
            </div>
            <div className={"content-info-item"}>
              <span>{formatCop(i.price.toString())}</span>
            </div>
            <div
              style={{
                backgroundImage: `url(${Consts.urlStatic}${
                  i["images"][0]
                    ? i["images"][0]["drive_key"]
                    : "images/im-n-disp.png"
                })`,
              }}
              key={i}
              className="content-item-caro "
            ></div>
          </div>
        </div>
      );
    });

  changeActiveItem = (activeItemIndex) => {
    this.setState({ activeItemIndex: activeItemIndex });
  };

  onFilterProducts = () => {
    this.setState({
      onFilterProducts: !this.state.onFilterProducts,
    });
  };

  headerStep(title, description, ct, carousel, filter) {
    return (
      <div
        className={`${window.innerWidth < 700 ? "style-pzed" : ""} header-step`}
      >
        <div className="content-title">
          {title !== "" ? (
            <span className="title-hstep">
              {title}
              {}
            </span>
          ) : (
            []
          )}
          {description ? <span className="desc-hstep">{description}</span> : []}
          {filter === false ? (
            <div
              onClick={this.onFilterProducts}
              className={"cur-p c-br btn-third"}
            >
              Filtrar por categoria
            </div>
          ) : (
            []
          )}
          {ct}
        </div>
        {filter !== false && carousel !== undefined ? (
          <div className={"content-carousel"}>{filter}</div>
        ) : (
          []
        )}
        {filter === false && carousel !== undefined ? (
          <div className="content-carousel">{carousel}</div>
        ) : (
          []
        )}
      </div>
    );
  }

  onValidateGeneralInfo = async () => {


    this.setState({
      remNameDelivery: this.props.infoDestinatary.Nombre,
      remEmailDelivery: this.props.infoDestinatary.EmailAddress,
      remPhoneDelivery: this.props.infoDestinatary.Telefono,
      destNameDelivery: this.props.infoDestinatary.Nombre,
      destPhoneDelivery: this.props.infoDestinatary.Telefono,
      destDirectionlDelivery: this.props.infoDestinatary.Direccion,
      destCityDelivery: this.props.infoDestinatary.City,
    });
    const validationResults = this.validateFields(this.state);
    const allFieldsValid = Object.values(validationResults).every(Boolean);


    if (!allFieldsValid) {
      Swal.fire(Consts.txErr, Consts.txBadInfo, "error");
      document.getElementById("stepPago").click();
      document.getElementById('button-select-location').click();
      return false;
    }
  };

  validateFields(state) {
    const nameRegex = /^[a-zA-ZáéíóúÁÉÍÓÚñÑ][a-zA-ZáéíóúÁÉÍÓÚñÑ ()]{1,50}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{7,15}$/;
    const isValid = {
        remNameDelivery: nameRegex.test(state.remNameDelivery),
        remEmailDelivery: emailRegex.test(state.remEmailDelivery),
        remPhoneDelivery: phoneRegex.test(state.remPhoneDelivery),
        destNameDelivery: nameRegex.test(state.destNameDelivery),
        destPhoneDelivery: phoneRegex.test(state.destPhoneDelivery),
        destDirectionlDelivery: state.destDirectionlDelivery && state.destDirectionlDelivery.trim().length > 0,
        destCityDelivery: state.destCityDelivery && state.destCityDelivery.trim().length > 0
    };
    return isValid;
}


  onGeneratePay = async () => {
    this.setState({
      validateForm: true,
    });
    if ((await this.onValidateGeneralInfo()) === false) {
      return;
    }

    if (!this.state.termsAccepted) {
      Swal.fire(Consts.txErr, Consts.txTerms, "error");
      return;
    }

    if (this.state.methodPay === 1) {
      this.onGenerateMercadoPay();
    }
    if (this.state.methodPay === 0) {
      this.onGenerateCashPay();
    }
  };

  onGenerateMercadoPay = async () => {
    this.onGenerateCcPay("mercadopago")
  }

  onGenerateCashPay = async () => {
    this.onGenerateCcPay("cash")
  }

  onGenerateCcPay = async (paymentMethod) => {
    const state = this.state;
    let bodyPay = {};
    let validFormPay =  
      !state.CardValid ||
      !state.ExpirationValid ||
      state.lengthCvv !== state.CVV.length;
    if (validFormPay && paymentMethod === undefined) {
      Swal.fire(Consts.txErr, Consts.txBadInfo, "error");
      return;
    }
    let totalUnformat = state.total;

    if (parseInt(totalUnformat) < 500) {
      Swal.fire(Consts.txErr, Consts.txValHigh, "error");
      return;
    }

    let dateDelivery =
      typeof state.dateDelivery === "number"
        ? moment
            .unix(parseInt(this.state.dateDelivery.toString().slice(0, 10)))
            .format()
        : state.dateDelivery;

    bodyPay["infoPay"] = {
      total: this.state.total+this.state.envio,
      details: state.detailsDelivery,
      message: state.messageDelivery,
      paymentMethod: paymentMethod === 'cash' | paymentMethod === 'mercadopago' ? paymentMethod : /^4[0-9]{12}(?:[0-9]{3})?$/.test(state.Card)
        ? "debit"
        : "credit",
      number: state.Card,
      securityCode: state.CVV,
      expirationDate: state.Expiration,
      name: state.NameTitular,
      dedicatory: state.remDedDelivery,
      dateDelivery: dateDelivery,
      cardNick: this.state.cardNick,
    };

    let directionShipping = state.destDirectionlDelivery?.split("#");
    if(directionShipping !== undefined)
      bodyPay["infoDelivery"] = {
        fullName: state.destNameDelivery,
        emailAddress: state.remEmailDelivery,
        contactPhone: state.destPhoneDelivery,
        dniNumber: state.NumberDocument,
        dniType: state.TypeClient.split(" ")[0],
        shippingAddress: {
          street1: directionShipping[0],
          street2: directionShipping[1],
          city: state.destCityDelivery,
          state: state.destStateDelivery,
          country: Consts.countrySg,
          postalCode: "111021",
          phone: state.destPhoneDelivery,
        },
      };

    let directionSender = state.destDirectionlDelivery?.split("#");
    if(directionSender !== undefined)
      bodyPay["infoSender"] = {
        fullName: state.remNameDelivery,
        emailAddress: state.remEmailDelivery,
        contactPhone: state.remPhoneDelivery,
        dniNumber: state.NumberDocument,
        dniType: state.TypeClient.split(" ")[0],
        billingAddress: {
          street1: directionSender[0],
          street2: directionSender[1],
          city: state.destCityDelivery,
          state: state.destStateDelivery,
          country: Consts.countrySg,
          postalCode: "000000",
          phone: state.remPhoneDelivery,
        },
      };

    bodyPay["verification"] = {
      deviceSessionId: this.state.deviceSessionId,
      ipAddress: this.state.ipAddress,
      cookie: document.cookie,
      userAgent: window.navigator.userAgent,
      apiKey: Consts.apiPayKey,
      apiLogin: Consts.apiPayLogin,
      accountId: Consts.accountPay,
      paymentMethod: paymentMethod,
      paymentCountry: Consts.countrySg,
      paymentFactory: Consts.slugFactory

    };
    bodyPay["store"] = state.storeItems;
    apiOnGenerateCCPay(bodyPay);
  };
}

export default connect(
  (state) => ({
    products: state.setInfoData.products,
    productsSuggestions: state.setInfoData.products,
    productForgotten: state.setInfoData.productForgotten,
    setBanks: state.setInfoData.setBanks,
    categories: state.setInfoData.categories,
    productsFilter: state.setInfoData.productsFilter,
    time_delivery: state.setInfoData.time_delivery,
    city: state.setInfoData.city,
    infoDestinatary: state.setInfoData.infoDestinatary,
  }),
  {
    GetProducts,
    GetProductsSuggestions,
    GetProductForgotten,
    GetBankList,
    GetCategories,
    GetProductsByCategory,
    GetTimeDelivery
  }
)(StoreShop);
