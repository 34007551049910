export const urlDev = "http://localhost:8000/api/";
export const urlDevAws = "https://dev.sand.artesaniasgb.com/api/";
export const urlStaging = "https://staging.sand.artesaniasgb.com/api/";
export const urlProd = "https://sand.artesaniasgb.com/api/";
export const urlStatic = "";
export const urlDeploy = "{VAR_BACKEND}";
export const urlBase = urlProd;
export const slugCountry = "colombia";
export const slugFactory = "artesaniasgb";


export const apiPayKeyDev = "4Vj8eK4rloUd272L48hsrarnUA";
export const apiPayLoginDev = "pRRXKOl8ikMmt9u";
export const accountPayDev = "512321";
export const apiPayKeyDeploy = "{VAR_API_PAY_KEY}";
export const apiPayLoginDeploy = "{VAR_API_PAY}";
export const accountPayDeploy = "{VAR_API_PAY_ACCOUNT}";
export const apiPayKey = apiPayKeyDeploy;
export const apiPayLogin = apiPayLoginDeploy;
export const accountPay = accountPayDeploy;

export const countrySg = "CO";

export const urlBanks = "https://srsb1a1nv9.execute-api.us-east-1.amazonaws.com/prod/banks";

export const txTitle = "ArtesaniasGb";
export const emailContact = "info@artesaniasgb.com";
export const txSite = "ArtesaniasGb.com";
export const txInstagram = "https://www.instagram.com/artesaniasycacharreriagb/";
export const txWhatsapp = "https://wa.me/573014172904";
export const txFacebook = "https://www.facebook.com/profile.php?id=61558691547680";

export const rightsRes = "Copyright 2020 © Todos los derechos reservados";
export const txPayComp = "Su pedido se ha completado.";
export const txPayInProceso = "Su pedido se ha procesado.";

export const txPayIncomp = "Su pedido no se ha completado.";
export const txBadInfoPay = "La informacion de pago no es correcta.";
export const txBadInfo = "La informacion no es correcta.";
export const txPayProg = "El pago esta siendo procesado..";
export const txProdAdd = "Su producto ha sido agregado";
export const txProdAddUpd = "Su producto ha sido actualizado";
export const txProdSold = "No hay mas unidades disponibles";
export const txSelCat = "Selecciona una Categoria";
export const txSelCoun = "Ubicacion de entrega";
export const txLoad = "Cargando..";
export const txComp = "Completado!";
export const txErr = "Error!";
export const txConf = "Confirmar";
export const txCanc = "Cancelar";
export const txValHigh = "El valor debe ser mayor.";

export const txProdSugg = "Sugerencias";
export const txOrBuy = "Tú Orden De Compra";
export const txProds = "Productos";
export const txProd = "Producto";
export const txMesStore = "Parte de tu pedido califica para envío GRATIS. Detalles";
export const txCurrency = "COP";
export const txSubTot = "Subtotal";
export const txEnvio = "Envio";
export const txTot = "Total:";
export const txCont = "Continuar!";
export const txAdd = "Agregar";
export const txBuy = "Comprar";
export const txRem = "Remitente";
export const txDest = "Entrega";
export const txAuxDest = "Información para la entrega";
export const txMsgDest = "Recuerda que esta es la informacion de entrega para los productos en los que no ingresaste un destinatario";
export const txConten = "Contenido";
export const txPay = "Pagar";
export const txPayment = "Pago";
export const txDateDel = "Fecha de despacho";
export const setDocs = [
  {
    "id": "0",
    "description": "Documento Identificación"
  },
  {
    "id": "1",
    "description": "CC Cédula de ciudadanía."
  },
  {
    "id": "2",
    "description": "CE Cédula de extranjería."
  },
  {
    "id": "3",
    "description": "NIT Número de Identificación Tributario."
  },
  {
    "id": "4",
    "description": "TI Tarjeta de Identidad."
  },
  {
    "id": "5",
    "description": "PP Pasaporte."
  },
  {
    "id": "6",
    "description": "IDC Identificador único de cliente, para el caso de ID’s únicos de clientes/usuarios de servicios públicos."
  },
  {
    "id": "7",
    "description": "CEL En caso de identificarse a través de la línea del móvil."
  },
  {
    "id": "8",
    "description": "RC Registro civil de nacimiento."
  },
  {
    "id": "9",
    "description": "DE Documento de identificación extranjero."
  }
];
export const setTypPers = [
  {
    "id": "0",
    "description": "Seleccione a continuación"
  },
  {
    "id": "1",
    "description": "PN Persona Natural"
  },
  {
    "id": "2",
    "description": "PJ Persona Jurídica"
  }
];

export const txThbuy = "Gracias por tu compra";
export const txMsgBuy = "en busca de tu fidelidad.";

export const terms = "Términos y condiciones";
export const txTerms = "Lea y acepte los terminos y condiciones.";
export const txMsgGen = "Ilumina el dia de tu ser apreciado";
export const txInfo = "Información";
export const txSugg = "Sugerido";
export const txSold = "Vendidos";

export const txBar1 = "Compra Segura";
export const txBar2 = "ArtesaniasGb | No esperes más y empieza a disfrutar";
export const txBar3 = "308 33 09";
export const txBar3V = "0313083309";
export const txBar4 = "302 375 0178";
export const txBar4V = "3023750178";

export const modalitieInitial = "";

export const txDescLocation = "La dirección determina las opciones y los tiempos de envío.";